.color-wrapper {
    display: flex;
    align-items: center;
}

.color-title {
    margin-right: 5px;
    color: white;
    font-size: 12px;
}

.color-block {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
}

.color-picker-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
}
