.column:nth-child(even) {
    margin-top: 25vh;
}

.column {
    position: relative;
    flex-basis: 50%;
    padding: 0 5%;
    box-sizing: border-box;
}

.project-actions-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    transition: .3s ease-out;
}

.parallax-container {
    position: relative;
    overflow: hidden;
}

.parallax-container:hover .project-actions-container {
    transform: translateY(0);
}

.work-name-container {
    display: flex;
    align-items: center;
    padding-top: 60px;
}

.work-name-title {
    padding-right: 20px;
    white-space: nowrap;
    opacity: .7;
    font-size: 11px;
    text-transform: uppercase;
}

.work-name-line {
    height: 1px;
    background: rgba(255,255,255,.3);
    width: 35%;
}

.work-description {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 300px;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 400;
}

.actions-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
}

.action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
    background: white;
    color: black;
    border-radius: 35px;
    z-index: 100;
    cursor: pointer;
}

.change-preview-input {
    display: none;
}

.remove-project img {
    width: 15px;
}

@media (max-width: 500px) {
    .column:nth-child(even) {
        margin-top: 0;
    }

    .column {
        width: 100%;
        padding: 0;
        margin-bottom: 100px;
    }
}