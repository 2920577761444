.bg-avatar-container {
    height: 80vh;
    max-height: 500px;
    overflow: hidden;
    cursor: pointer;
}

.custom-class {
    height: 100%;
    margin: 0;
}

.parallax-inner {
    height: 100%;
}

.bg-avatar {
    width: 100%;
    height: 120%;
    position: relative;
    background-position: center;
    background-size: cover;
}