.preview-img-wrapper {
    position: relative;
    margin-bottom: 100px;
    overflow: hidden;
}

.add-project {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 80vh;
    background: rgba(255, 255, 255, .05);
    overflow: hidden;
    transition: .3s;
    cursor: pointer;
}

.add-project:hover {
    background: rgba(255, 255, 255, .08);
}

.add-project:hover .add-project-plus-icon {
    width: 20%;
}

.add-project-plus-icon {
    width: 10%;
    transition: .15s ease-out;
}

.input-file {
    display: none;
}

.preview-img-actions-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    transition: .3s ease-out;
    z-index: 100;
}

.preview-img-wrapper:hover .preview-img-actions-container {
    transform: translateY(0);
}
