.preview-actions-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 -1px 15px 0 rgba(0,0,0,0.7);
    z-index: 100;
}

.preview-action {
    flex-basis: 50%;
    padding: 10px 0;
    background: rgba(0, 0, 0, .8);
    color: #c9c9c9;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
}
