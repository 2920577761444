.top-section-wrapper {
    height: 100vh;
    overflow: hidden;
}

.top-section {
    max-width: 800px;
    height: 100vh;
    margin: 0 auto;
    padding: 220px 0 120px;
    box-sizing: border-box;
}

.top-section h1 {
    font-weight: 100;
    font-size: 72px;
    line-height: 90px;
}

.top-section-span-animate-wrapper {
    display: block;
    overflow: hidden;
}

.top-section-span {
    display: block;
    animation: titleAnimate;
}

.works {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10vw;
    box-sizing: border-box;
}

@keyframes titleAnimate {
    0% {
        transform: translateY(150%);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 800px) {
    .top-section {
        padding: 220px 50px 120px;
    }

    .top-section h1 {
        font-size: 62px;
        line-height: 74px;
    }
}

@media (max-width: 500px) {

    .top-section-wrapper {
        height: auto;
    }

    .top-section {
        height: 70vh;
        display: flex;
        align-items: center;
        padding: 80px 25px 60px;
    }

    .top-section-span {
        font-size: 32px;
        line-height: 1.4;
    }

    .works {
        flex-direction: column;
        padding: 0 25px;
    }
}
