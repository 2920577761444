.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
    transition: .3s;
    z-index: 150;
}

.header-logo {
    position: relative;
    font-size: 24px;
    animation-duration: .6s;
    z-index: 200;
    cursor: pointer;
}

.header-links {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
}

.header-link-item {
    margin-left: 10px;
    transition: .25s ease-out;
}

.header-link-item img {
    width: 20px;
}

.header-link-item:hover {
    transform: scale(0.9);
}

.back-btn {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 24px;
    animation-duration: .6s;
    cursor: pointer;
    z-index: 200;
}

.back-btn img {
    width: 60%;
}

@media (max-width: 500px) {
    .header {
        padding: 30px 25px;
        box-sizing: border-box;
    }
}
